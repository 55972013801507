import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./0-start/start.module').then(m => m.StartModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: 'shadow',
    loadChildren: () => import('./shadow/shadow.module').then(m => m.ShadowModule)
  },
  {
    path: 'bindings',
    loadChildren: () => import('./bindings/bindings.module').then(m => m.BindingsModule)
  },
  {
    path: 'ondemand',
    loadChildren: () => import('./ondemand/ondemand.module').then(m => m.OndemandModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
